<template>
  <div class="show-main">
    <router-view
      :isBoxShowUpdate="isBoxShowUpdate"
      @handleForbidden="handleForbidden"
      @handleEnable="handleEnable"
      @handleSee="handleSee"
      @handleShowEdit="handleShowEdit"
      @getEditShow="getEditShow"
      @handleReturns="handleReturns"
      @showListUpdate="showListUpdate"
    ></router-view>
    <MyPhone
      :isBoxShowNum="isBoxShowNum"
      :list="list"
      :showId="showId"
      :templetId="templetId"
      @confirmDelShow="confirmDelShow"
      @cancelDelShow="cancelDelShow"
    ></MyPhone>
  </div>
</template>

<script>
import { getFarmShowInfoApi, delShowApi } from "@/request/api.js";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    MyPhone: () => import("@/components/MyPhone.vue"),
  },
  data() {
    return {
      showId: undefined,
      isBoxShowUpdate: false,
      // 模型  默认 8
      isBoxShowNum: 12,
      // 编辑参数
      title: "",
      templetId: "",
      show_id: "",
      imgUrls: "",
      list: [
        { img: "", title: "农场标题" },
        { img: "", title: "农场标题", tips: "农场图片" },
      ],
    };
  },
  created() {
    sessionStorage.setItem("navLeft", "-100vh");
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  methods: {
    ...mapMutations("user", ["SET_FARM_SHOW"]),

    getEditShow(val) {
      this.list = val;
    },
    showListUpdate() {
      this.isBoxShowUpdate = false;
    },
    async confirmDelShow() {
      this.isBoxShowUpdate = true;
      this.isBoxShowNum = 12;
      const { code } = await delShowApi({
        manor_show_id: this.showId,
      });
      if (code != 0) return;

      this.$notify({
        title: "成功",
        message: "已删除",
        type: "success",
      });
    },
    cancelDelShow() {
      this.isBoxShowNum = 12;
    },
    handleShowEdit(templetId, show_id) {
      this.templetId = templetId;
      this.showId = show_id;
      this.isBoxShowNum = 11;
      this.$router.push({
        name: "showEdit",
        query: {
          id: show_id,
          templetId,
        },
      });
    },
    // 分享
    handleEnable(templetId, show_id) {
      this.templetId = templetId;
      this.showId = show_id;
      // this.show_id = show_id;
      // this.getFarmShowInfoApi(show_id);
      this.isBoxShowNum = 8;
    },
    handleForbidden(id) {
      this.showId = id;
      this.isBoxShowNum = 9;
    },
    // 预览
    handleSee(templetId, show_id) {
      this.templetId = templetId;
      this.showId = show_id;
      this.show_id = show_id;
      this.isBoxShowNum = 10;
      // this.getFarmShowInfoApi(show_id);
    },
    handleReturns() {
      this.isBoxShowNum = 12;
    },
    async getFarmShowInfoApi(manor_show_id) {
      const { code, results } = await getFarmShowInfoApi({
        manor_id: this.farmID,
        manor_show_id,
      });
      if (code != 0) return;
      results.show_info.pids = JSON.parse(results.show_info.pids);
      let farmShow = results.show_info;
      farmShow.title = results.show_info.show_title;
      this.SET_FARM_SHOW({ farmShow });
    },
  },
};
</script>

<style lang="less" scoped>
.show-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #0e2138;
  padding: 37px 49px 0;
}
</style>
